<template>
  <main id="site-wrapper">
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Admin</a>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name:'user-management' }">
              User Management
            </router-link>
          </li>
          <li class="breadcrumb-item active">
            Create new user
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'user-management'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <div class="section-header-title-group">
              <h1>Create new user</h1>
              <p>Here you can create new users, set their role, plan and optional extras.</p>
            </div>
          </div>
        </div>
        <div class="row row-has-panel">
          <div class="col-xl-9">
            <div class="section-panel">
              <div class="section-panel-header">
                <div class="section-panel-header-title">
                  <h3>Add user by their email</h3>
                </div>
              </div>
              <div class="section-panel-body">
                <form
                  class="form-horizontal"
                  @submit.prevent=""
                >
                  <div class="form-group m-0">
                    <input
                      v-model="txtEmail"
                      class="form-control"
                      data-add-users="true"
                      type="text"
                      placeholder="Email address"
                      :class="{
                        'has-error': validEmailError
                      }"
                      @keyup="addEmail"
                      @focusout="checkEmail"
                    >
                    <p
                      v-if="emailAlreadyExistsError"
                      class="form-group-note error-text"
                    >
                      <icon-alert
                        class="mr-2 d-flex"
                        fill-color="red"
                      />
                      This email is already in your account, you cannot add them again.
                    </p>
                    <p
                      v-if="validEmailError"
                      class="form-group-note error-text"
                    >
                      <icon-alert
                        class="mr-2 d-flex"
                        fill-color="red"
                      />
                      This email is not valid, please review it.
                    </p>
                    <p class="form-group-note">
                      <icon-info
                        class="mr-2 d-flex"
                        fill-color="#144f66"
                      />
                      Separate email addresses with a comma
                    </p>
                  </div>
                  <div class="form-group m-0 mt-1 mt-lg-0">
                    <div class="added-users">
                      <div
                        v-for="item in emailList"
                        :key="item.email"
                        class="added-users-user"
                        :class="{ 'is-selected': item.isSelected }"
                        @click="item.isSelected = !item.isSelected"
                      >
                        {{ item.email }}
                      </div>
                    </div>
                  </div>
                  <hr>
                  <button
                    v-if="emailList.filter(item => item.isSelected).length > 0"
                    type="button"
                    data-delete="users"
                    class="btn btn-link"
                    @click="deleteSelItem()"
                  >
                    <icon-trash-primary class="mr-2" />
                    Delete selected emails
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl mt-2 mt-xl-0">
            <div class="section-panel">
              <div class="section-panel-header">
                <div
                  class="section-panel-header-title"
                  :class="{
                    'flex-column' : !authUser.is_provisioned
                  }"
                >
                  <h3>Pick a user plan</h3>
                  <p
                    v-if="!authUser.is_provisioned"
                    class="warning-text"
                  >
                    {{ 'You can upgrade this user to a paid plan once they have completed registration, in the User Management area.' }}
                  </p>
                </div>
              </div>
              <div class="section-panel-body">
                <div
                  v-for="plan in visiblePlanList"
                  :key="plan.id"
                  class="plan-option"
                  :class="{'is-checked': curPlanId === plan.id }"
                >
                  <div class="plan-option-header">
                    <span
                      :ref="`span-plan-${plan.id}`"
                      data-trigger="expandable"
                      @click="slideToggle(`plan-${plan.id}`)"
                    >{{ plan.name }}</span>
                    <div
                      v-if="isPlanLevelAtLeast(plan.level)"
                      class="plan-option-checkbox"
                    >
                      <input
                        :id="`check-plan-${plan.id}`"
                        type="checkbox"
                        :checked="curPlanId === plan.id"
                        @change="setCurrentPlan(plan.id, $event)"
                      >
                      <label :for="`check-plan-${plan.id}`" />
                    </div>
                    <div
                      v-else
                      class="plan-option-checkbox"
                    >
                      <input
                        :id="`check-plan-${plan.id}`"
                        type="checkbox"
                        @click.prevent="$emit('show-premium-feature-popup')"
                      >
                      <label :for="`check-plan-${plan.id}`" />
                    </div>
                  </div>
                  <div
                    :ref="`div-plan-${plan.id}`"
                    class="expandable"
                  >
                    <div class="expandable-content">
                      <p>
                        {{ plan.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr>
            <div class="text-right">
              <a
                href="#"
                class="btn btn-secondary"
                @click.prevent="addUser()"
              >
                <icon-plus class="mr-2 d-flex gray" /> Add user
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {userStore} from '@/store/__STORE_user';
import {billingStore} from '@/store/__STORE_billing';
import PlanMixin from '@/mixins/PlanMixin';
import IconPlus from '@/components/icons/IconPlus.vue';
import IconInfo from '@/components/icons/IconInfo.vue';
import IconAlert from '@/components/icons/IconAlert.vue';
import IconTrashPrimary from '@/components/icons/IconTrashPrimary.vue';

export default {
  components: {
    IconPlus,
    IconInfo,
    IconTrashPrimary,
    IconAlert,
  },
  mixins: [PlanMixin],
  data() {
    return {
      curPlanId: '',
      isAllUsers: false,
      txtEmail: '',
      emailList: [],
      validEmailError: false,
      emailAlreadyExistsError: false,
      // eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    };
  },
  computed: {
    planList: () => billingStore.plans,
    userList: () => userStore.subUsers,
    authUser: () => userStore.profileData,
    licenses: () => userStore.licenses,
    visiblePlanList() {
      if (this.authUser.is_provisioned) {
        const temp = this.licenses.filter((plan) => {
          return plan.type !== 'addon';
        });
        const freePlan = {
          id: 21,
          key: 'pp-no-license',
          name: 'No License',
          description: 'No license. User won\'t be able to do anything apart from logging in until another license is assigned.',
          assigned: 0,
          used: 0,
          available: 0,
        };
        temp.unshift(freePlan);
        const trialExpired = this.authUser.trial_expired_at !== null;
        const trialCount = this.licenses.length === 0 && !trialExpired ? this.userList.filter((user) => user.billing_plan.name === 'Trial').length : 4;
        if (trialCount < 3) {
          const trialPlan = {
            id: 22,
            key: 'pp-trial',
            name: 'Trial',
            description: 'Lets you try out most of the features for 30 days',
            assigned: 3,
            used: trialCount,
            available: (3 - trialCount),
          };
          temp.unshift(trialPlan);
        }
        return temp;
      } else {
        return this.planList.filter((plan) => {
          if (!this.authUser.billing_plan) {
            return false; // Dont show any options until authUser is loaded
          }

          if (plan.name === 'Free' && this.authUser.billing_plan.name === 'Trial') {
            return false;
          }

          if (plan.name === 'Trial' && this.authUser.billing_plan.name !== 'Trial') {
            return false;
          }

          // We only want free or trial plan now SV-651
          return !(plan.name === 'Business' || plan.name === 'Launch' || plan.name === 'Pro');
        });
      }
    },
  },
  created() {
    if (this.authUserLoadedAndCannotAccessAdminFeatures) {
      this.$router.push({name: 'dashboard'});
    }
    userStore.getLicenses();
    billingStore.getPlanData();
  },
  methods: {
    addEmail(event) {
      if (event.key === ',') {
        const curEmail = this.txtEmail.slice(0, -1);
        if (this.verifyEmail(curEmail)) {
          this.emailList.push({
            email: curEmail,
            isSelected: false,
          });
          this.txtEmail = '';
        } else {
          this.txtEmail = curEmail;
        }
      }
    },
    checkEmail() {
      if (this.verifyEmail(this.txtEmail)) {
        this.emailList.push({
          email: this.txtEmail,
          isSelected: false,
        });
        this.txtEmail = '';
      }
    },
    verifyEmail(email) {
      this.validEmailError = false;
      this.emailAlreadyExistsError = false;
      if (this.reg.test(email)) {
        if (!this.userList.some(
            (user) => {
              return user.email === email.toLowerCase();
            },
        )) {
          return true;
        } else {
          this.emailAlreadyExistsError = true;
          return false;
        }
      } else {
        if (email !== '') {
          this.validEmailError = true;
        }
        return false;
      }
    },
    addUser() {
      if (!this.curPlanId) {
        this.$toast.error('Please select one plan!');
        return;
      }
      if (this.emailList.length===0) {
        this.$toast.error('Please input one email at least!');
        return;
      }

      const realEmailList = [];
      this.emailList.forEach( (item) => {
        realEmailList.push( item.email);
      });
      const param = {
        plan_id: this.curPlanId,
        emails: realEmailList,
      };

      userStore.addSubUsers(param)
          .then(() => {
            this.emailList = [];
            userStore.getSubUsers().then(() => {
              this.$router.push({name: 'user-management'});
              this.$toast.success('Invites sent successfully');
            });
          })
          .catch((err) => {
            if (err.response.data) {
              const data= err.response.data;
              this.$toast.error(data.message);

              if (err.response.status === 422) {
                if (data.errors) {
                  for (const messages of Object.values(data.errors)) {
                    for (const msg of messages) {
                      this.$toast.error(msg);
                    }
                  }
                }
              }
            } else {
              this.$toast.error(err);
            }
          });
    },
    setCurrentPlan(id, event) {
      if (event.target.checked) {
        this.curPlanId = id;
      } else {
        this.curPlanId = '';
      }
    },
    slideToggle(id) {
      const expandWidget = this.$refs[`div-${id}`][0];
      const expandSpan = this.$refs[`span-${id}`][0];
      if (expandSpan.classList.value) {
        expandSpan.classList = [];
        expandWidget.style.maxHeight = '';
      } else {
        expandSpan.classList = ['is-active'];
        expandWidget.style.maxHeight = expandWidget.scrollHeight + 'px';
      }
    },
    deleteSelItem() {
      this.emailList = this.emailList.filter((item) => !item.isSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-text {
  margin: unset;
  padding: 15px 25px 0 25px;
}
</style>

