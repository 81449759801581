var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{attrs:{"id":"site-wrapper"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container-fluid"},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(0),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name:'user-management' }}},[_vm._v(" User Management ")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Create new user ")])]),_c('div',{staticClass:"section-header"},[_c('div',{staticClass:"section-header-title"},[_c('router-link',{staticClass:"section-header-back",attrs:{"to":{name: 'user-management'}}},[_c('i',{staticClass:"fas fa-arrow-circle-left"})]),_vm._m(1)],1)]),_c('div',{staticClass:"row row-has-panel"},[_c('div',{staticClass:"col-xl-9"},[_c('div',{staticClass:"section-panel"},[_vm._m(2),_c('div',{staticClass:"section-panel-body"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"form-group m-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.txtEmail),expression:"txtEmail"}],staticClass:"form-control",class:{
                      'has-error': _vm.validEmailError
                    },attrs:{"data-add-users":"true","type":"text","placeholder":"Email address"},domProps:{"value":(_vm.txtEmail)},on:{"keyup":_vm.addEmail,"focusout":_vm.checkEmail,"input":function($event){if($event.target.composing)return;_vm.txtEmail=$event.target.value}}}),(_vm.emailAlreadyExistsError)?_c('p',{staticClass:"form-group-note error-text"},[_c('icon-alert',{staticClass:"mr-2 d-flex",attrs:{"fill-color":"red"}}),_vm._v(" This email is already in your account, you cannot add them again. ")],1):_vm._e(),(_vm.validEmailError)?_c('p',{staticClass:"form-group-note error-text"},[_c('icon-alert',{staticClass:"mr-2 d-flex",attrs:{"fill-color":"red"}}),_vm._v(" This email is not valid, please review it. ")],1):_vm._e(),_c('p',{staticClass:"form-group-note"},[_c('icon-info',{staticClass:"mr-2 d-flex",attrs:{"fill-color":"#144f66"}}),_vm._v(" Separate email addresses with a comma ")],1)]),_c('div',{staticClass:"form-group m-0 mt-1 mt-lg-0"},[_c('div',{staticClass:"added-users"},_vm._l((_vm.emailList),function(item){return _c('div',{key:item.email,staticClass:"added-users-user",class:{ 'is-selected': item.isSelected },on:{"click":function($event){item.isSelected = !item.isSelected}}},[_vm._v(" "+_vm._s(item.email)+" ")])}),0)]),_c('hr'),(_vm.emailList.filter(item => item.isSelected).length > 0)?_c('button',{staticClass:"btn btn-link",attrs:{"type":"button","data-delete":"users"},on:{"click":function($event){return _vm.deleteSelItem()}}},[_c('icon-trash-primary',{staticClass:"mr-2"}),_vm._v(" Delete selected emails ")],1):_vm._e()])])])]),_c('div',{staticClass:"col-xl mt-2 mt-xl-0"},[_c('div',{staticClass:"section-panel"},[_c('div',{staticClass:"section-panel-header"},[_c('div',{staticClass:"section-panel-header-title",class:{
                  'flex-column' : !_vm.authUser.is_provisioned
                }},[_c('h3',[_vm._v("Pick a user plan")]),(!_vm.authUser.is_provisioned)?_c('p',{staticClass:"warning-text"},[_vm._v(" "+_vm._s('You can upgrade this user to a paid plan once they have completed registration, in the User Management area.')+" ")]):_vm._e()])]),_c('div',{staticClass:"section-panel-body"},_vm._l((_vm.visiblePlanList),function(plan){return _c('div',{key:plan.id,staticClass:"plan-option",class:{'is-checked': _vm.curPlanId === plan.id }},[_c('div',{staticClass:"plan-option-header"},[_c('span',{ref:`span-plan-${plan.id}`,refInFor:true,attrs:{"data-trigger":"expandable"},on:{"click":function($event){return _vm.slideToggle(`plan-${plan.id}`)}}},[_vm._v(_vm._s(plan.name))]),(_vm.isPlanLevelAtLeast(plan.level))?_c('div',{staticClass:"plan-option-checkbox"},[_c('input',{attrs:{"id":`check-plan-${plan.id}`,"type":"checkbox"},domProps:{"checked":_vm.curPlanId === plan.id},on:{"change":function($event){return _vm.setCurrentPlan(plan.id, $event)}}}),_c('label',{attrs:{"for":`check-plan-${plan.id}`}})]):_c('div',{staticClass:"plan-option-checkbox"},[_c('input',{attrs:{"id":`check-plan-${plan.id}`,"type":"checkbox"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('show-premium-feature-popup')}}}),_c('label',{attrs:{"for":`check-plan-${plan.id}`}})])]),_c('div',{ref:`div-plan-${plan.id}`,refInFor:true,staticClass:"expandable"},[_c('div',{staticClass:"expandable-content"},[_c('p',[_vm._v(" "+_vm._s(plan.description)+" ")])])])])}),0)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('hr'),_c('div',{staticClass:"text-right"},[_c('a',{staticClass:"btn btn-secondary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addUser()}}},[_c('icon-plus',{staticClass:"mr-2 d-flex gray"}),_vm._v(" Add user ")],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Admin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-header-title-group"},[_c('h1',[_vm._v("Create new user")]),_c('p',[_vm._v("Here you can create new users, set their role, plan and optional extras.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-panel-header"},[_c('div',{staticClass:"section-panel-header-title"},[_c('h3',[_vm._v("Add user by their email")])])])
}]

export { render, staticRenderFns }